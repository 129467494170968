import { useEffect, useRef, useState } from "react";
import Changer from "../../components/Changer";
import { OfferInt, useDataPackage } from "../../context/DataPackage.context";
import { useNavigate, useParams } from "react-router-dom";
import { useChanges } from "../../context/Changes.context";
import { useChange } from "../../context/Change.context";
import SelectGBdesc from "../../components/SelectGBdesc";
import NotReady from "../../components/NotReady";
import DestForm from "../DestForm";
import DestDetail from "../DestDetail";
import useOrdersService from "../../services/orders.service";
import { formatMoney, formatSeparate } from '../../functions/formats';
import { currencies } from "../../data/currencies";
import TitleGB from "../../components/TitleGB";
import "./styles/offer.css";

interface OptionsInt {
    label: string;
    value: any;
    extra?: string | React.ReactElement;
}

const OfferSend = () => {
    const { offer: offer_id } = useParams<{ offer: string }>();
    const { offers, beneficiaries } = useDataPackage();
    const { changes } = useChanges();
    const { limitMax, limitMin, setFrom, setFromOp, setToOp, fromOp, toOp, from, to, setterLimitMin, currentSide, setCurrentSide } = useChange();
    const navigate = useNavigate();
    const [offer, setOffer] = useState<OfferInt>();
    const [selectBlocker, setSelectBlocker] = useState(true);
    const [innerBenef, setInnerBenef] = useState(0);
    const formRef = useRef<HTMLDivElement>(null);
    const [beneff, setBeneff] = useState<OptionsInt[]>([]);
    const { send } = useOrdersService();

    useEffect(() => {
        if (offer_id && isNaN(Number(offer_id)) === false && changes && offers) {
            const offerData = offers.find(({ id }) => id === Number(offer_id));
            if (offerData) {
                setCurrentSide('left');
                setOffer(offerData);
                const [ideal] = changes.filter(({ currency_from }) => currency_from === offerData.from_data).map(({ currency_get }) => currency_get)
                setFromOp(offerData.from_data);
                setToOp(ideal);
                setFrom(Number(offerData.val_from));
            }
        } else {
            navigate("/perfil/ofertas");
        }
    }, [offers, beneficiaries]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (limitMax.state === true || from === 0) {
            setSelectBlocker(true);
        } else {
            setSelectBlocker(false);
        }
    }, [limitMax, limitMin]);

    useEffect(() => {
        const benef: OptionsInt[] = [{
            label: "Nuevo Destinatario",
            value: 1,
            extra: "-"
        }];
        if (beneficiaries) {
            beneficiaries.filter(benefInfo => benefInfo.operation === toOp).map(({ alias_id, alias_name }) => {
                benef.push({
                    label: alias_name,
                    value: alias_id,
                    extra: "*"
                });
            });
            setBeneff(benef);
        }
    }, [beneficiaries, toOp]);

    const handleSendDest = async () => {
        if (offer) {
            if (limitMin.number > from) {
                setterLimitMin(true);
                return;
            }

            if (innerBenef === 0 || innerBenef === 1) {
                return;
            }

            send({
                from_data: fromOp,
                to_data: toOp,
                val_from: from,
                val_to: to,
                beneficiary: innerBenef,
                operation: currentSide,
                offer_id: offer.id
            });
        }
    }

    return (
        <div>
            {
                offer && (
                    <div className="offer-body">
                        <TitleGB>Oferta de {formatSeparate(offer.from_data.slice(0, -3))}</TitleGB>
                        <div className="offer-numbers">
                            <div className="offer-numbes-titles">
                                <p>Envías</p>
                            </div>
                            <div>
                                <p><small>${formatMoney(from)}</small></p>
                                <p>${formatMoney(offer.real_val_from, offer.from_data === "PayPalUSD" ? 2 : 0)} {currencies[offer.from_data.slice(-3)]}</p>
                            </div>
                        </div>
                        <div className="offer-numbers">
                            <div className="offer-numbes-titles">
                                <p>Recibes </p>
                            </div>
                            <p>${formatMoney(to, toOp === "PayPalUSD" ? 2 : 0)} {currencies[toOp.slice(-3)]}</p>
                        </div>
                        <p style={{margin: '20px 0', fontSize: 'small', opacity: 0.7}}>Elige donde quieres recibir tu envío</p>
                        <Changer offer />
                        <div className="send-screen-forms">
                            {
                                !selectBlocker && (
                                    <div className="send-main-box">
                                        <h3>¿Quién recibirá tu envío?</h3>
                                        <div className="send-main-select">
                                            <SelectGBdesc
                                                options={beneff}
                                                value={innerBenef}
                                                onChange={(val) => { setInnerBenef(Number(val)); setTimeout(() => formRef.current?.scrollIntoView({ block: "start", behavior: "smooth" }), 100) }}
                                                variant="outline"
                                                placeHolder="Elige quien recibe"
                                                disabled={selectBlocker}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div ref={formRef}>
                                {
                                    !selectBlocker && (
                                        innerBenef === 1 ? (
                                            <DestForm key={toOp} offerId={offer.id} />
                                        ) : innerBenef > 1 ? (
                                            <DestDetail innerBenef={innerBenef} send={handleSendDest} />
                                        ) : (
                                            <NotReady />
                                        )
                                    )

                                }
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                )
            }
        </div>
    )
}

export default OfferSend;