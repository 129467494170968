import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { completeOrderInt } from "../../context/DataPackage.context";
import { formatDate } from '../../functions/formats';
import { IoMdArrowBack } from "react-icons/io";
import useOrdersService from "../../services/orders.service";
import EditAccount from "./EditAccount";
import Timer from "./Timer";
import { GiAlarmClock } from "react-icons/gi";
import { MdOutlinePending } from "react-icons/md";
import { MdOutlineScheduleSend } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import Detail from "./Detail";
import Payments from "../Payments/Payments";
import "./styles/order.css";
import { usePopUp } from "../../context/PopUp.context";

const Order = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const [order, setOrder] = useState<completeOrderInt>();
    const [mountInfo, setMountInfo] = useState(0);
    const navigate = useNavigate();
    const { refreshOrder, getCompleteOrder } = useOrdersService();
    const { setPopUp, setState } = usePopUp();

    const setter = (data: any) => {
        setOrder(data);
        let pending = 0;
        data.payments_to.forEach(({ amount, payment_state }: any) => {
            if (payment_state === "pending") {
                pending += Number(amount)
            }
        });
        pending += Number(data.pending_val_to);
        setMountInfo(pending);
    }

    useEffect(() => {
        if (orderId) {
            getCompleteOrder(orderId)
                .then(data => setter(data))
                .catch(() => navigate('/perfil/envios'))
        }
    }, []);

    useEffect(() => {
        if (orderId && order && order.order_state === "created") {
            const timeoutId = setTimeout(() => {
                refreshOrder(orderId).then(data => setter(data));
            }, 60000);

            if (order.payments_from.length > 1) {
                setPopUp({
                    content: <Alert />,
                    title: "¡Atención!",
                    customCloseButtonTitle: "Entendido"
                });
                setState(true);
            }

            return () => clearTimeout(timeoutId);
        }
    }, [order]);

    const Alert = () => (
        <div>
            <p>El pago de tu envío ha sido divido en partes por favor <b>no envíes el monto total a una sola cuenta</b>, revisa detenidamente cada pago, el monto y su cuenta asignada. </p>
            <br />
            <p>Si requieres ayuda, por favor comunícate con nosotros.</p>
        </div>
    )

    return (
        order && (
            <div>
                <div className="order-sub-container">
                    <div className="order-sub-back" onClick={() => navigate("/perfil/envios")}>
                        <IoMdArrowBack size={window.innerWidth > 450 ? 18 : 13} />
                        <p>Volver</p>
                    </div>
                    <h2 className="order-inner-title">{order.order_id}</h2>
                    <OrderState state={order.order_state} />
                </div>
                <div className="order-container">
                    <div className="order-resume">
                        <Detail
                            option="Envías"
                            operation={order.from_data}
                            amount={order.val_from}
                            realAmount={order.real_val_from}
                            biggerLength={order.from_data.length > order.to_data.length ? order.from_data.length : order.to_data.length}
                        />
                        <Detail
                            option="Enviamos"
                            operation={order.to_data}
                            amount={order.val_to}
                            biggerLength={order.from_data.length > order.to_data.length ? order.from_data.length : order.to_data.length}
                        />
                    </div>
                    {
                        order.order_state === "created" && (
                            <Timer order={order} />
                        )
                    }
                    <p className="order-super-date">{formatDate(new Date(order.action_date))}</p>
                    {
                        order.beneficiary_account.account_state === 3 && (
                            <EditAccount account={order.beneficiary_account} />
                        )
                    }
                    <Payments
                        payments_from={order.payments_from}
                        payments_to={order.payments_to}
                        benef={order.beneficiary_account}
                        order_id={order.order_id}
                        order_state={order.order_state}
                        mountInfo={mountInfo}
                    />
                </div>
            </div>
        )
    )
}

const OrderState = ({ state, iconSize = 22 }: { state: string, iconSize?: number }) => (
    <div style={{ display: "flex", alignItems: "center", color: 'gray', width: "100%", justifyContent: "flex-end" }}>
        <p style={{ marginRight: "10px" }}>
            {
                state === "created"
                    ? "Pendiente"
                    : state === "pending"
                        ? "Revisando"
                        : state === "paid"
                            ? "Recibido"
                            : state === "sending"
                                ? "Enviando"
                                : state === "completed"
                                    ? "Completado"
                                    : state === "cancelled" || state === "cancelled_by_admin"
                                        ? "Cancelado"
                                        : state === "cancelled_by_client"
                                            ? "Has cancelado este envío"
                                            : null
            }
        </p>
        {
            state === "created"
                ? <GiAlarmClock size={iconSize} />
                : state === "pending"
                    ? <MdOutlinePending size={iconSize} />
                    : state === "paid"
                        ? <MdOutlinePending size={iconSize} />
                        : state === "sending"
                            ? <MdOutlineScheduleSend size={iconSize} />
                            : state === "completed"
                                ? <FaCheck />
                                : state === "cancelled" || state === "cancelled_by_admin" || state === "cancelled_by_client"
                                    ? <MdOutlineCancel size={iconSize} />
                                    : null
        }
    </div>
);

export default Order;