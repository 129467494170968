import { createContext, useContext, useEffect, useRef, useState } from "react";
import usePageService from "../services/page.service";
import { Loading } from "../components/Loading";

interface changesInt {
    id: number;
    currency_from: string;
    currency_get: string;
    base_price: number;
    fee: number;
}

interface changesStateInt {
    change_name: string;
    limit_min: number;
    limit_max: number;
}

interface ContextChangesInt {
    changes: changesInt[] | null;
    changesState: changesStateInt[] | null;
}

const ChangesContext = createContext<ContextChangesInt | null>(null);


interface ChangesProps {
    children: React.ReactNode;
}

const ChangesProvider: React.FC<ChangesProps> = ({ children }) => {

    const { getChanges } = usePageService();
    const [changes, setChanges] = useState<changesInt[] | null>(null);
    const [changesState, setChangesState] = useState<changesStateInt[] | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const get = () => {
        getChanges()
            .then(result => {
                setChanges(result.changesResult);
                setChangesState(result.changesState);
            })
            .catch(() => {
                setChanges(null);
            })
    }

    useEffect(() => {

        const startFetChing = () => {
            get();
            intervalRef.current = setInterval(get, 30000);
        }

        const handleVisibilityChange = () => {
            if (document.hidden) {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            } else {
                if (!intervalRef.current) {
                    startFetChing();
                }
            }
        }

        // get();
        // const int = setInterval(get, 30000);

        document.addEventListener('visibilitychange', handleVisibilityChange);
        startFetChing();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };

    }, []);

    return (
        <ChangesContext.Provider value={
            {
                changes,
                changesState
            }
        }>
            {
                changes && changesState ? (
                    children
                ) : (
                    <Loading />
                )
            }
        </ChangesContext.Provider>
    );
}


const useChanges = (): ContextChangesInt => {
    const context = useContext(ChangesContext);
    if (!context) {
        throw new Error('useChanges debe ser utilizado dentro de ChangesProvider');
    }
    return context;
};

export { ChangesProvider, useChanges };

