import React, { useState, createContext, useContext, useEffect } from 'react';
import axios from "axios";
interface CountryInt {
    country: string;
    setCountry: (param: string) => void;
}

const ConectionContext = createContext<CountryInt | null>(null);

interface CountryProps {
    children: React.ReactNode;
}

const CountryProvider: React.FC<CountryProps> = ({ children }) => {

    const [country, setCountry] = useState("");

    useEffect(() => {
        const fetchCountry = () => {
            if (localStorage.getItem("_colcambios_country")) {
                const country = localStorage.getItem("_colcambios_country")!;
                setCountry(country);
            } else {
                axios.get('https://ipinfo.io/country?token=59126a3de9a756')

                    .then(response => {
                        const data = response.data.slice(0, -1);
                        const count = data !== "CO" && data !== "AR" ? "US" : data;
                        localStorage.setItem("_colcambios_country", count);
                        setCountry(count);
                    })

                    .catch(error => {
                        console.error('Error fetching country:', error);
                        setCountry("US");
                    })
            }
        };

        fetchCountry();
    }, []);

    return (
        <ConectionContext.Provider value={
            {
                country,
                setCountry,
            }
        }>
            {children}
        </ConectionContext.Provider>
    );
};




const useCountry = (): CountryInt => {
    const context = useContext(ConectionContext);
    if (!context) {
        throw new Error('useCountry debe ser utilizado dentro de CountryProvider');
    }
    return context;
};

export { CountryProvider, useCountry };