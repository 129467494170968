import "./styles/popupgb.css";
import colcambiosLogo from "../assets/images/logo-white.png";
import ButtonGB from "./ButtonGB";
import { useEffect } from "react";

interface PopUpGBInt {
    setState: (param: boolean) => void;
    children: React.ReactNode;
    title?: string;
    confirmButton?: React.ReactNode;
    onClose?: () => void;
    customCloseButtonTitle?: string;
}

const PopUpGB: React.FC<PopUpGBInt> = ({ setState, children, title, confirmButton, onClose, customCloseButtonTitle = "Volver" }) => {

    useEffect(() => {
        if ("vibrate" in navigator) {
            navigator.vibrate(200);
        }
        document.body.scrollIntoView({ block: "start", behavior: 'instant' })
    }, []);

    const handleClose = () => {
        setState(false);
        if (onClose) {
            onClose();
        }
    }

    return (
        <div className="popUpGB-overlay" onClick={handleClose}>
            <div className="popUpGB-content" onClick={(e) => e.stopPropagation()}>
                <img
                    alt="logo-colcambios"
                    src={colcambiosLogo}
                    className="popUpGB-bg-image"
                />
                <div className="popUpGB-body">
                    {
                        title && <h1 className="popUpGB-title">{title}</h1>
                    }
                    {children}

                    <div className="popUpGB-buttons">
                        {confirmButton}
                        <ButtonGB
                            title={customCloseButtonTitle}
                            style={{
                                backgroundColor: "orange"
                            }}
                            onClick={handleClose}
                        />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default PopUpGB;